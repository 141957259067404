<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          :showKeyWord="false"
          :showYearRow="true"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="list" v-for="item in dataList" :key="'row'+item.id">
          <div class="topRow">
            <div class="type">{{typeOptions[item.type]}}</div>
            <div class="topRowDes h2">{{item.patentNo}}</div>
            <div class="topRowDes h2">{{item.patentName}}</div>
          </div>
          <div class="des">
            <div class="des-content">
              <label class="des-name h2">有效性:</label>
              {{availabilityOptions[item.availability]}}
            </div>
            <div class="des-content">
              <label class="des-name h2">公开(公告)号:</label>
              {{item.noticeNo}}
            </div>
            <div class="des-content">
              <label class="des-name h2">公开(公告)日:</label>
              {{item.noticeDate}}
            </div>
            <div class="des-content">
              <label class="des-name h2">申请号:</label>
              {{item.applyNo}}
            </div>
            <div class="des-content">
              <label class="des-name h2">申请人:</label>
              {{item.applyName}}
            </div>
            <div class="des-content">
              <label class="des-name h2">发明人:</label>
              {{item.inventor}}
            </div>
            <div class="des-content">
              <label class="des-name h2">IPC分类号:</label>
              {{item.ipcNo}}
            </div>
            <div class="item-des">
              <label class="des-name h2">摘要:</label>
              {{item.abstractInfo}}
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'patentList',
  components: {
    TopFilter
  },
  data() {
    return {
      typeOptions: {
        "1": '外观专利',
        "2": '发明专利',
        "3": '实用新型'
      },
      availabilityOptions: {
        "1": '在审',
        "2": '有效',
        "3": '无效',
        "4": '其他'
      },
      sortConfig: [],
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      typeList: [
        {
          name: '分类',
          code: 'type',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '外观设计',
              val: 1,
            },
            {
              name: '发明专利',
              val: 2,
            },
            {
              name: '实用新型',
              val: 3,
            }
          ]
        },
        {
          name: '专利有效性',
          code: 'availability',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '在审',
              val: 1,
            },
            {
              name: '有效',
              val: 2,
            },
            {
              name: '无效',
              val: 3,
            },
            {
              name: '其他',
              val: 4,
            }
          ]
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.intellectualProperty.searchList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  //margin-top: 20px;
  padding: 30px;
  .list {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .topRow {
      display: flex;
      width: 40%;
      flex-direction: row;
      justify-content: space-between;
      height: 30px;
      line-height: 30px;
      .type {
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: white;
        border: 0px;
        background-color: red;
      }
      .topRowDes {
        height: 30px;
        line-height: 30px;
      }
    }
    .des {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      width: 100%;
      height: 200px;
      align-items: start;
      margin: 10px 0;
      .des-row {
        display: flex;
        flex-direction: row;
      }
      .des-name {
        white-space: nowrap;
        font-size: 14px;
      }
      .des-content {
        font-size: 14px;
      }
      .item-des {
        text-align: left;
        font-size: 14px;
        color: grey;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
